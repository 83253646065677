<template>
  <div class="editable-content">
    <base-icon v-if="$api.isAdmin()" @click="editContent">edit</base-icon>
    <slot :content="editable"></slot>
  </div>
</template>

<script>
export default {
  name: 'editableContent',

  props: {
    path: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      editable: { // Note: has to be object for data to be reactive
        content: ''
      }
    }
  },

  provide () { // provide reactive data for child component
    return {
      editableContentData: this.editable
    }
  },

  watch: {
    editableContent: {
      immediate: true,
      handler: function() {
        this.editable.content = this.editableContent
      }
    }
  },

  computed: {
    localization() {
      // tehään tää lokalisaatio yhteen paikkaan, ettei tartte eri komponentteihin aina tehä tätä computedia
      return this.$store.state.app.constlet.inEnglish ? 'en' : 'fi'
    },

    isImage() {
      let innerComponent = this.$slots.default[0].componentOptions.tag

      if (innerComponent === 'base-image') return true
      else return false
    },

    editableContent() {
      const imageSource = this.$store.state.api.imageURL + this.path.replace('.', '/') + '/'
      let path = this.path.split('.')
      let content = this.isImage
        ? this.$store.state.content.images
        : this.$store.state.content.text[this.localization]

      while (path.length > 0) {
        content = content[path.shift()]
      }

      return this.isImage
        ? imageSource + content
        : content
    }
  },

  methods: {
    editContent() {
      let data = {
         path: {
           collection: 'content',
           document: this.isImage ? 'images' : 'text',
           property: this.isImage ? this.path : this.localization +'.' +this.path
         }
      }

      if (this.isImage) { data.image = this.editableContent }
      else { data.text = this.editableContent }

      this.$store.dispatch('modals/SET_MODAL', { active: 'editContent', data: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-content {
  .base-icon {
    @extend %clickable;
    color: $app-color--hl;
  }
}
</style>
